import { PRICE } from ".";

export const menuItems = [
  // Combos
  {
    name: "L1. Sweet & Sour Chicken Balls",
    price: PRICE.menu_items.l1.toFixed(2),
    category: ["Special Combo"],
  },
  {
    name: "L2 Beef Chow Mein",
    price: PRICE.menu_items.l2.toFixed(2),
    category: ["Special Combo"],
  },
  {
    name: "L3. Beef with Mixed Vegetables",
    price: PRICE.menu_items.l3.toFixed(2),
    category: ["Special Combo"],
  },
  {
    name: "L4. Sesame Chicken",
    price: PRICE.menu_items.l4.toFixed(2),
    category: ["Special Combo"],
  },
  {
    name: "L5. Beef with Broccoli",
    price: PRICE.menu_items.l5.toFixed(2),
    category: ["Special Combo"],
  },
  {
    name: "L6. Beef with Green Pepper",
    price: PRICE.menu_items.l6.toFixed(2),
    category: ["Special Combo"],
  },
  {
    name: "L7. Chicken with Vegetable & Almond",
    price: PRICE.menu_items.l7.toFixed(2),
    category: ["Special Combo"],
  },
  {
    name: "L8. Lemon Chicken",
    price: PRICE.menu_items.l8.toFixed(2),
    category: ["Special Combo"],
  },
  {
    name: "L9. Sweet & Sour Shrimp",
    price: PRICE.menu_items.l9.toFixed(2),
    category: ["Special Combo"],
  },
  {
    name: "L10. Sweet & Sour Wonton",
    price: PRICE.menu_items.l10.toFixed(2),
    category: ["Special Combo"],
  },
  {
    name: "L11. General Tso's Chicken",
    price: PRICE.menu_items.l11.toFixed(2),
    category: ["Special Combo"],
  },
  // Appetizers
  {
    name: "Egg Roll",
    price: PRICE.menu_items.egg_roll.toFixed(2),
    category: ["Appetizers"],
  },
  {
    name: "Spring Roll",
    price: PRICE.menu_items.spring_roll.toFixed(2),
    category: ["Appetizers"],
  },
  {
    name: "Sliced Barbecued Pork",
    price: PRICE.menu_items.sliced_barbecued_pork.toFixed(2),
    category: ["Appetizers"],
  },
  {
    name: "Honey Garlic Spareribs",
    price: PRICE.menu_items.honey_garlic_spareribs.toFixed(2),
    category: ["Appetizers"],
  },
  {
    name: "Jar Doo Chicken Wings",
    price: PRICE.menu_items.jar_doo_chicken_wings.toFixed(2),
    category: ["Appetizers"],
  },
  {
    name: "Dry Garlic Spareribs",
    price: PRICE.menu_items.dry_garlic_spareribs.toFixed(2),
    category: ["Appetizers"],
  },
  {
    name: "Dumpling",
    price: PRICE.menu_items.dumpling.toFixed(2),
    category: ["Appetizers"],
  },
  {
    name: "Beef Teriyaki (10)",
    price: PRICE.menu_items.beef_teriyaki_10.toFixed(2),
    category: ["Appetizers"],
  },
  {
    name: "Bo Bo Tray for 2",
    price: PRICE.menu_items.bo_bo_tray_for_2.toFixed(2),
    category: ["Appetizers"],
  },
  {
    name: "Extra Person",
    price: PRICE.menu_items.extra_person.toFixed(2),
    category: ["Appetizers"],
  },
  // Lo Mein

  {
    name: "Cantonese Chow Mein",
    price: PRICE.menu_items.cantonese_chow_mein.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Seafood Cantonese Chow Mein",
    price: PRICE.menu_items.seafood_cantonese_chow_mein.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Lo Mein",
    price: PRICE.menu_items.lo_mein.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Chicken Lo Mein",
    price: PRICE.menu_items.chicken_lo_mein.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Beef Lo Mein",
    price: PRICE.menu_items.beef_lo_mein.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Shrimp Lo Mein",
    price: PRICE.menu_items.shrimp_lo_mein.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Vegetable Lo Mein",
    price: PRICE.menu_items.vegetable_lo_mein.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Chicken Special Fried Noodles",
    price: PRICE.menu_items.chicken_special_fried_noodles.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Beef Special Fried Noodles",
    price: PRICE.menu_items.beef_special_fried_noodles.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Pork Special Fried Noodles",
    price: PRICE.menu_items.pork_special_fried_noodles.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Pan Fried Noodle",
    price: PRICE.menu_items.pan_fried_noodle.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Soft Noodle",
    price: PRICE.menu_items.soft_noodle.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Shanghai Noodles (No Meat)",
    price: PRICE.menu_items.shanghai_noodles_no_meat.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Chicken Shangai Noodles",
    price: PRICE.menu_items.chicken_shanghai_noodles.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Beef Shangai Noodles",
    price: PRICE.menu_items.beef_shanghai_noodles.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Pork Shanghai Noodles ",
    price: PRICE.menu_items.pork_shanghai_noodles.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "New: Udon Chicken",
    price: PRICE.menu_items.udon_chicken.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "New: Udon Beef",
    price: PRICE.menu_items.udon_beef.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "New: Udon Pork",
    price: PRICE.menu_items.udon_pork.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "New: Beef Black Bean Rice Noodle",
    price: PRICE.menu_items.beef_black_bean_rice_noodle.toFixed(2),
    category: ["Lo Mein (Noodle)"],
  },
  // Soup
  {
    name: "Wonton Soup (L)",
    price: PRICE.menu_items.wonton_soup_lg.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Wonton Soup (S)",
    price: PRICE.menu_items.wonton_soup_s.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Consomme Soup (L)",
    price: PRICE.menu_items.consomme_soup_lg.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Consomme Soup (S)",
    price: PRICE.menu_items.consomme_soup_s.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Egg Drop Soup (S)",
    price: PRICE.menu_items.egg_drop_soup_s.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Egg Drop Soup (L)",
    price: PRICE.menu_items.egg_drop_soup_l.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Chicken Noodle Soup (L)",
    price: PRICE.menu_items.chicken_noodle_soup_l.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Chicken Noodle Soup (S)",
    price: PRICE.menu_items.chicken_noodle_soup_s.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Chicken Rice Soup (L)",
    price: PRICE.menu_items.chicken_rice_soup_l.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Chicken Rice Soup (S)",
    price: PRICE.menu_items.chicken_rice_soup_s.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Wonton Mein Soup",
    price: PRICE.menu_items.wonton_mein_soup.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Pork Vet Guy Mein Soup",
    price: PRICE.menu_items.pork_vet_guy_mein_soup.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "Deep Fried Wonton Soup",
    price: PRICE.menu_items.deep_fried_wonton_soup_l.toFixed(2),
    category: ["Soup"],
  },
  {
    name: "New: Udon Soup",
    price: PRICE.menu_items.udon_soup.toFixed(2),
    category: ["Soup"],
  },
  // Sweet & Sour
  {
    name: "Sweet & Sour Wontons",
    price: PRICE.menu_items.sweet_sour_wontons.toFixed(2),
    category: ["Sweet & Sour"],
  },
  {
    name: "Sweet & Sour Chicken Balls",
    price: PRICE.menu_items.sweet_sour_chicken_balls.toFixed(2),
    category: ["Sweet & Sour"],
  },
  {
    name: "Sweet & Sour Spareribs",
    price: PRICE.menu_items.sweet_sour_spareribs.toFixed(2),
    category: ["Sweet & Sour"],
  },
  {
    name: "Sweet & Sour Shrimp",
    price: PRICE.menu_items.sweet_sour_shrimp.toFixed(2),
    category: ["Sweet & Sour"],
  },
  // Egg Foo Young
  {
    name: "Mushroom Egg Foo Young",
    price: PRICE.menu_items.mushroom_egg_foo_young.toFixed(2),
    category: ["Egg Foo Young"],
  },
  {
    name: "Chicken Egg Foo Young",
    price: PRICE.menu_items.chicken_egg_foo_young.toFixed(2),
    category: ["Egg Foo Young"],
  },
  {
    name: "Beef Egg Foo Young",
    price: PRICE.menu_items.beef_egg_foo_young.toFixed(2),
    category: ["Egg Foo Young"],
  },
  {
    name: "Barbecued Pork Egg Foo Young",
    price: PRICE.menu_items.barbecued_pork_egg_foo_young.toFixed(2),
    category: ["Egg Foo Young"],
  },
  {
    name: "Shrimp Egg Foo Young",
    price: PRICE.menu_items.shrimp_egg_foo_young.toFixed(2),
    category: ["Egg Foo Young"],
  },

  {
    name: "Tie Pink Egg Foo Young",
    price: PRICE.menu_items.tie_pink_egg_foo_young.toFixed(2),
    category: ["Egg Foo Young"],
  },
  // Rice
  {
    name: "Steamed Rice",
    price: PRICE.menu_items.steamed_rice.toFixed(2),
    category: ["Rice"],
  },
  {
    name: "Mushroom Fried Rice",
    price: PRICE.menu_items.mushroom_fried_rice.toFixed(2),
    category: ["Rice"],
  },
  {
    name: "Chicken Fried Rice",
    price: PRICE.menu_items.chicken_fried_rice.toFixed(2),
    category: ["Rice"],
  },
  {
    name: "Beef Fried Rice",
    price: PRICE.menu_items.beef_fried_rice.toFixed(2),
    category: ["Rice"],
  },
  {
    name: "Barbecued Pork Fried Rice",
    price: PRICE.menu_items.barbecued_pork_fried_rice.toFixed(2),
    category: ["Rice"],
  },
  {
    name: "Shrimp Fried Rice",
    price: PRICE.menu_items.shrimp_fried_rice.toFixed(2),
    category: ["Rice"],
  },
  {
    name: "Tie Pink Special Fried Rice",
    price: PRICE.menu_items.tie_pink_special_fried_rice.toFixed(2),
    category: ["Rice"],
  },
  {
    name: "Pan Fried Noodle",
    price: PRICE.menu_items.pan_fried_noodle.toFixed(2),
    category: ["Rice"],
  },
  {
    name: "Soft Noodle",
    price: PRICE.menu_items.soft_noodle.toFixed(2),
    category: ["Rice"],
  },
  // Chop Suey & Chow Mein
  {
    name: "Vegetable Chop Suey",
    price: PRICE.menu_items.vegetable_chop_suey.toFixed(2),
    category: ["Chow Mein"],
  },
  {
    name: "Chicken Chop Suey",
    price: PRICE.menu_items.chicken_chop_suey.toFixed(2),
    category: ["Chow Mein"],
  },

  {
    name: "Beef Chop Suey",
    price: PRICE.menu_items.beef_chop_suey.toFixed(2),
    category: ["Chow Mein"],
  },
  {
    name: "Pork Chop Suey",
    price: PRICE.menu_items.pork_chop_suey.toFixed(2),
    category: ["Chow Mein"],
  },
  {
    name: "Shrimp Chop Suey",
    price: PRICE.menu_items.shrimp_chop_suey.toFixed(2),
    category: ["Chow Mein"],
  },
  {
    name: "Vegetable Chow Mein",
    price: PRICE.menu_items.vegetable_chow_mein.toFixed(2),
    category: ["Chow Mein"],
  },
  {
    name: "Chicken Chow Mein",
    price: PRICE.menu_items.chicken_chow_mein.toFixed(2),
    category: ["Chow Mein"],
  },
  {
    name: "Beef Chow Mein",
    price: PRICE.menu_items.beef_chow_mein.toFixed(2),
    category: ["Chow Mein"],
  },
  {
    name: "Pork Chow Mein",
    price: PRICE.menu_items.pork_chow_mein.toFixed(2),
    category: ["Chow Mein"],
  },
  {
    name: "Shrimp Chow Mein",
    price: PRICE.menu_items.shrimp_chow_mein.toFixed(2),
    category: ["Chow Mein"],
  },
  // Vegetables
  {
    name: "Mixed Vegetables with Almonds",
    price: PRICE.menu_items.mixed_vegetables_with_almonds.toFixed(2),
    category: ["Vegetables"],
  },
  {
    name: "Mixed Chinese Vegetables",
    price: PRICE.menu_items.mixed_chinese_vegetables.toFixed(2),
    category: ["Vegetables"],
  },
  {
    name: "Chinese Green (Bok Choy)",
    price: PRICE.menu_items.chinese_green_bok_choy.toFixed(2),
    category: ["Vegetables"],
  },
  {
    name: "Chinese Vegetables with Tofu",
    price: PRICE.menu_items.chinese_vegetables_with_tofu.toFixed(2),
    category: ["Vegetables"],
  },
  {
    name: "Szechuan Vegetable",
    price: PRICE.menu_items.szechuan_vegetable.toFixed(2),
    category: ["Vegetables"],
  },
  {
    name: "Vegetable Shanghai Noodles",
    price: PRICE.menu_items.vegetable_shanghai_noodles.toFixed(2),
    category: ["Vegetables"],
  },
  {
    name: "Vegetable Special Fried Noodles",
    price: PRICE.menu_items.vegetable_special_fried_noodles.toFixed(2),
    category: ["Vegetables"],
  },
  // Northern Chinese
  {
    name: "Hot & Spicy Chicken",
    price: PRICE.menu_items.hot_spicy_chicken.toFixed(2),
    category: ["Northern Chinese"],
  },
  {
    name: "Hot & Spicy Beef",
    price: PRICE.menu_items.hot_spicy_beef.toFixed(2),
    category: ["Northern Chinese"],
  },
  {
    name: "Sizzling Seafood Plate",
    price: PRICE.menu_items.sizzling_seafood_plate.toFixed(2),
    category: ["Northern Chinese"],
  },
  {
    name: "Fried Cashew Nuts & Chicken",
    price: PRICE.menu_items.fried_cashew_nuts_chicken.toFixed(2),
    category: ["Northern Chinese"],
  },
  {
    name: "Fried Cashew Nuts & Shrimp",
    price: PRICE.menu_items.fried_cashew_nuts_shrimp.toFixed(2),
    category: ["Northern Chinese"],
  },
  // Szechuan Dishes
  {
    name: "Spicy Chicken",
    price: PRICE.menu_items.spicy_chicken.toFixed(2),
    category: ["Szechuan"],
  },
  {
    name: "Spicy Beef",
    price: PRICE.menu_items.spicy_beef.toFixed(2),
    category: ["Szechuan"],
  },
  {
    name: "Szechuan Chicken",
    price: PRICE.menu_items.szechuan_chicken.toFixed(2),
    category: ["Szechuan"],
  },
  {
    name: "Szechuan Beef",
    price: PRICE.menu_items.szechuan_beef.toFixed(2),
    category: ["Szechuan"],
  },
  {
    name: "Szechuan Shrimp",
    price: PRICE.menu_items.szechuan_shrimp.toFixed(2),
    category: ["Szechuan"],
  },
  {
    name: "Hunan Chicken",
    price: PRICE.menu_items.hunan_chicken.toFixed(2),
    category: ["Szechuan"],
  },
  {
    name: "Hunan Beef",
    price: PRICE.menu_items.hunan_beef.toFixed(2),
    category: ["Szechuan"],
  },
  {
    name: "Kung Po Chicken",
    price: PRICE.menu_items.kung_po_chicken.toFixed(2),
    category: ["Szechuan"],
  },
  {
    name: "Kung Po Beef",
    price: PRICE.menu_items.kung_po_beef.toFixed(2),
    category: ["Szechuan"],
  },
  {
    name: "Chicken Shanghai Noodles",
    price: PRICE.menu_items.chicken_shanghai_noodles.toFixed(2),
    category: ["Szechuan"],
  },
  {
    name: "Beef Shanghai Noodles",
    price: PRICE.menu_items.beef_shanghai_noodles.toFixed(2),
    category: ["Szechuan"],
  },
  {
    name: "Pork Shanghai Noodles",
    price: PRICE.menu_items.pork_shanghai_noodles.toFixed(2),
    category: ["Szechuan"],
  },

  //Pork
  {
    name: "Pork with Broccoli",
    price: PRICE.menu_items.pork_with_broccoli.toFixed(2),
    category: ["Pork"],
  },
  {
    name: "Pork with Vegetables & Almonds",
    price: PRICE.menu_items.pork_with_vegetables_almonds.toFixed(2),
    category: ["Pork"],
  },
  {
    name: "Pork with Green Peppers",
    price: PRICE.menu_items.pork_with_green_peppers.toFixed(2),
    category: ["Pork"],
  },
  {
    name: "Pork with Mushrooms",
    price: PRICE.menu_items.pork_with_mushrooms.toFixed(2),
    category: ["Pork"],
  },

  //Beef
  {
    name: "Beef with Green Pepper",
    price: PRICE.menu_items.beef_with_green_pepper.toFixed(2),
    category: ["Beef"],
  },
  {
    name: "Beef with Vegetables & Almonds",
    price: PRICE.menu_items.beef_with_vegetables_almonds.toFixed(2),
    category: ["Beef"],
  },
  {
    name: "Beef with Mushrooms",
    price: PRICE.menu_items.beef_with_mushrooms.toFixed(2),
    category: ["Beef"],
  },
  {
    name: "Beef with Broccoli",
    price: PRICE.menu_items.beef_with_broccoli.toFixed(2),
    category: ["Beef"],
  },
  {
    name: "Curry Beef",
    price: PRICE.menu_items.curry_beef.toFixed(2),
    category: ["Beef"],
  },
  {
    name: "Beef with Mixed Vegetables",
    price: PRICE.menu_items.beef_with_mixed_vegetables.toFixed(2),
    category: ["Beef"],
  },
  {
    name: "Beef with Broccoli & Mushrooms",
    price: PRICE.menu_items.beef_with_broccoli.toFixed(2),
    category: ["Beef"],
  },
  {
    name: "Beef with Green Pepper & Mushrooms",
    price: PRICE.menu_items.chicken_or_beef_with_green_pepper_mushrooms.toFixed(2),
    category: ["Beef"],
  },
  {
    name: "Black Pepper Steak",
    price: PRICE.menu_items.black_pepper_steak.toFixed(2),
    category: ["Beef"],
  },
  // Poultry
  {
    name: "Lemon Chicken",
    price: PRICE.menu_items.lemon_chicken.toFixed(2),
    category: ["Poultry (Chicken)"],
  },
  {
    name: "General Toa Chicken",
    price: PRICE.menu_items.general_tso_s_chicken.toFixed(2),
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Sesame Chicken",
    price: PRICE.menu_items.sesame_chicken.toFixed(2),
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Soo Guy with Almonds",
    price: PRICE.menu_items.soo_guy_with_almonds.toFixed(2),
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Chicken with Vegetables & Almonds",
    price: PRICE.menu_items.chicken_with_vegetables_almonds.toFixed(2),
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Chicken with Broccoli",
    price: PRICE.menu_items.chicken_with_broccoli.toFixed(2),
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Curry Chicken",
    price: PRICE.menu_items.curry_chicken.toFixed(2),
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Chicken with Mixed Vegetables",
    price: PRICE.menu_items.chicken_with_mixed_vegetables.toFixed(2),
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Chicken with Broccoli & Mushrooms",
    price: PRICE.menu_items.chicken_with_broccoli_mushrooms.toFixed(2),
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Chicken Mushroom & Green Pepper",
    price: PRICE.menu_items.chicken_or_beef_with_green_pepper_mushrooms.toFixed(2),
    category: ["Poultry (Chicken)"],
  },
  //Seafood
  {
    name: "Shrimps with Vegetables & Almonds",
    price: PRICE.menu_items.shrimps_with_vegetables_almonds.toFixed(2),
    category: ["Seafood"],
  },
  {
    name: "Shrimp with Green Pepper & Mushrooms",
    price: PRICE.menu_items.shrimp_with_green_pepper_mushrooms.toFixed(2),
    category: ["Seafood"],
  },
  {
    name: "Shrimp with Mixed Vegetables",
    price: PRICE.menu_items.shrimp_with_mixed_vegetables.toFixed(2),
    category: ["Seafood"],
  },
  {
    name: "Shrimp with Lobster Sauce",
    price: PRICE.menu_items.shrimp_with_lobster_sauce.toFixed(2),
    category: ["Seafood"],
  },
  {
    name: "Shrimp with Broccoli & Mushrooms",
    price: PRICE.menu_items.shrimp_with_broccoli_mushrooms.toFixed(2),
    category: ["Seafood"],
  },
  {
    name: "Curry Shrimps",
    price: PRICE.menu_items.curry_shrimps.toFixed(2),
    category: ["Seafood"],
  },
  {
    name: "Shrimps with Mushrooms",
    price: PRICE.menu_items.shrimps_with_mushrooms.toFixed(2),
    category: ["Seafood"],
  },
  //Special Suggestions
  {
    name: "Tai Dop Voy",
    price: PRICE.menu_items.tai_dop_voy.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Cantonese Chow Mein",
    price: PRICE.menu_items.cantonese_chow_mein.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Lo Mein",
    price: PRICE.menu_items.lo_mein.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Chicken Lo Mein",
    price: PRICE.menu_items.chicken_lo_mein.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Beef Lo Mein",
    price: PRICE.menu_items.beef_lo_mein.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Shrimp Lo Mein",
    price: PRICE.menu_items.shrimp_lo_mein.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Moo Goo Guy Pan",
    price: PRICE.menu_items.moo_goo_guy_pan.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "So Chow Wonton",
    price: PRICE.menu_items.so_chow_wonton.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "General Tso's Chicken",
    price: PRICE.menu_items.general_tso_s_chicken.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Seafood Delight",
    price: PRICE.menu_items.seafood_delight.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Sesame Chicken",
    price: PRICE.menu_items.sesame_chicken.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Tie Pink's Special Chop Suey",
    price: PRICE.menu_items.tie_pink_s_special_chop_suey.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Black Pepper Steak",
    price: PRICE.menu_items.black_pepper_steak.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Seafood Cantonese Chow Mein",
    price: PRICE.menu_items.seafood_cantonese_chow_mein.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Curry Rice Vermicelli",
    price: PRICE.menu_items.curry_rice_vermicelli.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Chicken Special Fried Noodles",
    price: PRICE.menu_items.chicken_special_fried_noodles.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Beef Special Fried Noodles",
    price: PRICE.menu_items.beef_special_fried_noodles.toFixed(2),
    category: ["Special Suggestions"],
  },
  {
    name: "Pork Special Fried Noodles",
    price: PRICE.menu_items.pork_special_fried_noodles.toFixed(2),
    category: ["Special Suggestions"],
  },
  // Family Dinners
  {
    name: "Deluxe Dinner for 2",
    price: PRICE.menu_items.deluxe_dinner_for_2.toFixed(2),
    items: [
      "Bo Bo Tray",
      "Sweet & Sour Chicken Balls",
      "Beef with Vegetable & Almonds",
      "Chicken Fried Rice",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Special Dinner for 2",
    price: PRICE.menu_items.special_dinner_for_2.toFixed(2),
    items: [
      "2 Egg Rolls",
      "General Tso's Chicken",
      "Chicken Fried Rice",
      "Cantonese Chow Mein",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 2",
    price: PRICE.menu_items.dinner_for_2.toFixed(2),
    items: [
      "2 Egg Rolls",
      "Sweet & Sour Chicken Balls",
      "Beef Chop Suey",
      "Chicken Fried Rice",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 3",
    price: PRICE.menu_items.dinner_for_3.toFixed(2),
    items: [
      "3 Egg Rolls",
      "Sweet & Sour Chicken Balls",
      "Chicken with Vegetable & Almonds",
      "Beef Chop Suey",
      "Chicken Fried Rice",
      "Fortune Cooks",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 4",
    price: PRICE.menu_items.dinner_for_4.toFixed(2),
    items: [
      "4 Egg Rolls",
      "Sweet & Sour Chicken Balls",
      "Beef Chow Mein",
      "Cantonese Chow Mein",
      "Chicken Fried Rice (2 Orders)",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 5",
    price: PRICE.menu_items.dinner_for_5.toFixed(2),
    items: [
      "4 Egg Rolls",
      "Sweet & Sourc Chicken Balls",
      "Beef with Green Pepper",
      "Breaded Shrimp with Lemon",
      "Cantonese Chow Mein",
      "Beef Chop Suey",
      "Chicken Fried Rice (2 orders)",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 6",
    price: PRICE.menu_items.dinner_for_6.toFixed(2),
    items: [
      "6 Egg Rolls",
      "Sweet & Sour Chicken Balls",
      "Breaded Shrimp with Lemon",
      "Cantonese Chow Mein",
      "Beef Chop Suey",
      "So Chow Wonton",
      "Chicken Fried Rice (2 orders)",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 8",
    price: PRICE.menu_items.dinner_for_8.toFixed(2),
    items: [
      "8 Egg Rolls",
      "Sweet & Sour Chicken (2 orders)",
      "Sweet & Sour Shrimp (2 orders)",
      "Chicken with Vegetable Almond",
      "Cantonese Chow Mein",
      "Beef Chop Suey",
      "Chicken Fried Rice (2 orders)",
      "Jar Doo Chicken Wings",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 10",
    price: PRICE.menu_items.dinner_for_10.toFixed(2),
    items: [
      "10 Egg Rolls",
      "Sweet & Sour Chicken Balls (2 orders)",
      "Sweet & Sour Shrimp (2 orders)",
      "Beef with Broccoli (2 orders)",
      "General Tso's Chicken (2 orders)",
      "Cantonese Chow Mein",
      "Beef Chop Suey",
      "Chicken Fried Rice (2 orders)",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
];
