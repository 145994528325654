
import './styles/App.scss';
import Home from "./views/Home"
import Nav from "./views/Nav"
import Menu from "./views/Menu"
import Contact from "./views/Contact";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import react, { useState } from "react";
function App() {
  const [navOn, setNavOn] = useState(false);
  const closeNav = () => {
    setNavOn(false);
  }
  const openNav = () => {
    setNavOn(true);
  }
  return (
    <div className="App">
      <Router>
        <Nav navOn={navOn} closeNav={closeNav} openNav={openNav} />
        <Routes>
          <Route exact path="/" element={(<Home />)} />
          <Route path="/menu" element={(<Menu />)} />
          <Route path="/contact" element={(<Contact />)} />
          <Route path="/*" element={(<h1 className="page-404">404 Page Not Found</h1>)} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
