export const PRICE = {
  discount_threshold: 35,
  modifiers: {
    no_mushrooms: 1.0,
    no_onions: 1.0,
    no_peppers: 1.0,
    no_garlic: 1.0,
    no_broccoli: 1.0,
    no_celery: 1.0,
    no_egg: 1.0,
    no_vegetables: 1.0,
    no_chicken: 1.0,
    no_beef: 1.0,
    no_shrimp: 1.0,
    no_meat: 1.0,
    no_sauce: 1.0,
    no_sweet_and_sour_sauce: 0.0,
    no_carrot: 1.0,
    no_gravy: 1.0,
    no_seafood: 1.0,
    no_gluten: 1.0,
    no_soy_sauce: 1.0,
    no_spicy: 1.0,
    sauce_on_side: 1.0,
    no: 0.0,
    add_onions: 1.0,
    add_peppers: 1.0,
    add_garlic: 1.0,
    add_broccoli: 1.0,
    add_celery: 1.0,
    add_vegetables: 1.0,
    add_chicken: 1.0,
    add_beef: 1.0,
    add_shrimp: 1.0,
    add_meat: 1.0,
    add_egg: 1.0,
    add_sauce: 1.0,
    add_carrot: 1.0,
    add_gravy: 1.0,
    add_seafood: 1.0,
    add_gluten: 1.0,
    add_soy_sauce: 1.0,
    add_spicy: 1.0,
    add_sauce_on_side: 1.0,
    add: 0,
  },
  dine_in_items: {
    //Cans
    coke_can: 1.5,
    pepsi_can: 1.5,
    gingerale_can: 1.5,
    ice_tea_can: 1.5,
    rootbeer_can: 1.5,
    sprite_can: 1.5,
    diet_coke_can: 1.5,
    orange_crush_can: 1.5,
    seven_up_can: 1.5,
    // Bottle Pop
    coke_bottle: 2.5,
    pepsi_bottle: 2.5,
    gingerale_bottle: 2.5,
    diet_coke_bottle: 2.5,
    orange_crush_bottle: 2.5,
    seven_up_bottle: 2.5,
    // Water
    bottle_natural_water: 1.5,
    glass_water: 0,
    // Beer
    canadian_beer: 5.0,
    blue_beer: 5.0,
    budlight_beer: 5.0,
    coors_light_beer: 5.0,
    budweiser_beer: 5.0,
    heineken_beer: 5.0,
    export_beer: 5.0,
    // Liquour
    mix_drink_liquor: 6.0,
    shot_liquor: 6.0,
    rum_coke_liquor: 6.0,
    coke_whiskey: 6.0,

    // TEA
    regular_tea: 1.5,
    jasmine_tea: 1.5,
    coffee: 2.5,
    white_milk: 2.0,
    chocolate_milk: 2.0,
    // Juice
    apple_juice: 2.0,
    orange_juice: 2.0,
  },
  combo: {
    special_combo: 20.95,
  },
  menu_items: {
    //#region -------------------------------------------- Appetizers ----------------------------------------------- //
    egg_roll: 2.0,
    spring_roll: 2.0,
    sliced_barbecued_pork: 10.95,
    honey_garlic_spareribs: 13.45,
    jar_doo_chicken_wings: 11.5,
    dry_garlic_spareribs: 11.95,
    dumpling: 12.95,
    beef_teriyaki_10: 15.95,


    //#region -------------------------------------------- Combination Appetizers ----------------------------------------------- //

    bo_bo_tray_for_2: 17.95,
    extra_person: 9.0,

    //#region ----------------------------------------------------------- Soups ----------------------------------------------------------- //

    wonton_soup_s: 4.5,
    wonton_soup_lg: 6.95,

    egg_drop_soup_s: 4.5,
    egg_drop_soup_l: 6.95,

    chicken_noodle_soup_s: 4.5,
    chicken_noodle_soup_l: 6.95,

    chicken_rice_soup_s: 4.5,
    chicken_rice_soup_l: 6.95,

    wonton_mein_soup: 7.5,
    pork_vet_guy_mein_soup: 10.95,

    deep_fried_wonton_soup_s: 4.5,
    deep_fried_wonton_soup_l: 6.95,

    consomme_soup_s: 3.5,
    consomme_soup_lg: 5.0,
    //#region ----------------------------------------------------------- Sweet and Sour ----------------------------------------------------------- //

    sweet_sour_wontons: 10.5,
    sweet_sour_chicken_balls: 12.5,
    sweet_sour_spareribs: 11.25,
    sweet_sour_shrimp: 12.5,
    //#region ----------------------------------------------------------- Egg Fu Young ----------------------------------------------------------- //

    mushroom_egg_foo_young: 12.5,

    chicken_egg_foo_young: 12.5,
    beef_egg_foo_young: 12.5,
    barbecued_pork_egg_foo_young: 12.5,

    shrimp_egg_foo_young: 13.5,
    tie_pink_egg_foo_young: 13.5,
    //#region ----------------------------------------------------------- Rice ----------------------------------------------------------- //

    steamed_rice: 3.0,
    mushroom_fried_rice: 9.5,

    chicken_fried_rice: 9.95,
    beef_fried_rice: 9.95,
    barbecued_pork_fried_rice: 9.95,
    vegetable_fried_rice: 9.95,

    shrimp_fried_rice: 10.95,
    tie_pink_special_fried_rice: 10.95,

    pan_fried_noodle: 6.5,
    soft_noodle: 6.5,

    //#region ----------------------------------------------------------- Chop Suey & Chow Mein ----------------------------------------------------------- //

    vegetable_chop_suey: 9.95,

    chicken_chop_suey: 10.75,
    beef_chop_suey: 10.75,
    pork_chop_suey: 10.75,

    shrimp_chop_suey: 11.75,


    vegetable_chow_mein: 10.45,
    
    chicken_chow_mein: 11.25,
    beef_chow_mein: 11.25,
    pork_chow_mein: 11.25,

    shrimp_chow_mein: 12.25,

    //#region ----------------------------------------------------------- Vegetables ----------------------------------------------------------- //

    mixed_vegetables_with_almonds: 11.50,
    mixed_chinese_vegetables: 11.50,
    chinese_green_bok_choy: 11.50,
    chinese_vegetables_with_tofu: 11.75,
    szechuan_vegetable: 11.95,
    vegetable_shanghai_noodles: 12.95,
    vegetable_special_fried_noodles: 12.25,
  
    //#region ----------------------------------------------------------- New ----------------------------------------------------------- //

    chicken_pad_thai: 12.50,
    beef_pad_thai: 12.50,
    
    udon_vegetable: 12.50,
    udon_chicken: 12.50,
    udon_beef: 12.50,
    udon_pork: 12.50,

    beef_black_bean_rice_noodle: 12.5,

    beef_black_bean: 12.95,
    chicken_black_bean: 12.95,

    orange_chicken: 13.50,

    udon_soup: 9.95,

    //#region ---------------------------- Northern Chinese ----------------------------- //

    hot_spicy_chicken: 13.95,
    hot_spicy_beef: 13.95,
    sizzling_seafood_plate: 15.5,
    fried_cashew_nuts_chicken: 12.95,
    fried_cashew_nuts_shrimp: 13.95,
    //#region ------------------------------------------ Szechuan Dishes -----------------------------//

    spicy_chicken: 12.95,
    spicy_beef: 12.95,

    szechuan_chicken: 12.95,
    szechuan_beef: 12.95,

    szechuan_shrimp: 13.95,

    hunan_chicken: 12.95,
    hunan_beef: 12.95,

    kung_po_chicken: 12.95,
    kung_po_beef: 12.95,

    chicken_shanghai_noodles: 14.95,
    beef_shanghai_noodles: 14.95,
    pork_shanghai_noodles: 14.95,
    shanghai_noodles_no_meat: 12.95,
    //#region ----------------------------------- Pork ----------------------------------//

    pork_with_broccoli: 12.95,
    pork_with_vegetables_almonds: 12.5,
    pork_with_green_peppers: 12.5,
    pork_with_mushrooms: 12.95,
    //#region --------------------------------- Beef -------------------------------- //

    beef_with_green_pepper: 12.5,
    beef_with_vegetables_almonds: 12.5,
    beef_with_mushrooms: 12.95,
    beef_with_broccoli: 12.95,
    curry_beef: 12.5,
    beef_with_mixed_vegetables: 12.5,
    beef_with_broccoli_mushrooms: 12.95,
    chicken_or_beef_with_green_pepper_mushrooms: 12.95,

    //#region ---------------------------------------- Poultry ------------------------------------ //

    lemon_chicken: 12.95,
    soo_guy_with_almonds: 12.5,
    chicken_with_vegetables_almonds: 12.5,
    chicken_with_broccoli: 12.95,
    curry_chicken: 12.5,
    chicken_with_mixed_vegetables: 12.5,
    chicken_with_broccoli_mushrooms: 12.95,

    //#region ----------------------------------------------------------- Seafood ----------------------------------------------------------- //

    shrimps_with_vegetables_almonds: 13.95,
    shrimp_with_green_pepper_mushrooms: 13.95,
    shrimp_with_mixed_vegetables: 13.95,
    shrimp_with_lobster_sauce: 13.95,
    shrimp_with_broccoli_mushrooms: 13.95,
    curry_shrimps: 13.95,
    shrimps_with_mushrooms: 14.25,
    //#region ------------------------------------ Special Suggestions ----------------------------//

    tai_dop_voy: 13.50,
    cantonese_chow_mein: 15.50,

    lo_mein: 15.50,
    chicken_lo_mein: 15.50,
    beef_lo_mein: 15.50,
    pork_lo_mein: 15.50,
    vegetable_lo_mein: 15.50,
    shrimp_lo_mein: 15.5,

    moo_goo_guy_pan: 12.95,
    so_chow_wonton: 12.95,
    general_tso_s_chicken: 13.95,
    seafood_delight: 15.95,
    sesame_chicken: 13.95,
    tie_pink_s_special_chop_suey: 13.25,
    black_pepper_steak: 12.95,
    seafood_cantonese_chow_mein: 15.95,
    curry_rice_vermicelli: 13.75,

    chicken_special_fried_noodles: 13.25,
    beef_special_fried_noodles: 13.25,
    pork_special_fried_noodles: 13.25,

    //#region ----------------------------------------------------------- Family Dinners ----------------------------------------------------------- //

    dinner_for_1: 16.0,

    special_dinner_for_2: 40.95,

    deluxe_dinner_for_2: 40.95,
    dinner_for_2: 29.5,
    dinner_for_3: 41.95,
    dinner_for_4: 54.95,
    dinner_for_5: 68.95,
    dinner_for_6: 75.95,
    dinner_for_8: 110.95,
    dinner_for_10: 135.95,
    // Special Combos

    l1: 9.95,

    l2: 11.95,
    l3: 11.95,
    l4: 11.95,
    l5: 11.95,
    l6: 11.95,
    l7: 11.95,
    l8: 11.95,
    l9: 11.95,

    l10: 9.5,

    l11: 11.95,
    //#region ----------------------------------------------------------- Side Orders ----------------------------------------------------------- //

    french_fries: 5.0,
    extra_soy_sauce: 1.0,
    //#region ----------------------------------------------------------- Canadian Dishes ----------------------------------------------------------- //

    extra_plum_sauce: 1.0,
    extra_mustard: 1.0,
    dry_noodles: 1.0,
    //#region ----------------------------------------------------------- Family Meals ----------------------------------------------------------- //

    extra_green_onions: 2.0,
    // Drinks

    pop: 1.5,
    bottle_natural_water: 1.5,
    //#region ----------------------------------------------------------- Extra ----------------------------------------------------------- //

    sweet_sour_sauce_s: 2.0,
    sweet_sour_sauce_l: 3.0,
    lemon_sauce_s: 1.0,
    lemon_sauce_l: 2.0,
    gravy_s: 1.5,
    honey_sauce: 1.5,
    chop_sticks: 1.0,
    utensils: 1.0,
    bottle_gingerale: 2.5,
    bottle_orange_crush: 2.5,
    extra_hot_sauce: 1.0,
    //#region ----------------------------------------------------------- Extra Meat ----------------------------------------------------------- //
    extra_chicken_x: 3.5,
    extra_chicken_xx: 5.5,
    extra_beef_x: 3.5,
    extra_beef_xx: 5.5,
  },
};
