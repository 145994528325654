import React from "react";
import Pattern from "../Assets/Images/Pattern.png";
import Map from "../Assets/Images/Map.png";
function Contact() {
  return (
    <div className="contact-container">
      <div className="contact-pattern-1">
        <img src={Pattern} alt="pattern" />
      </div>
      <div className="contact-pattern-2">
        <img src={Pattern} alt="pattern" />
      </div>
      <div className="container contact-content">
        <header className="contact-header">
          <h1 className="page-title">CONTACT</h1>
        </header>
        <div className="contact-number-container">
          <p className="contact-number-title">Restaurant Phone Number</p>
          <a className="contact-number" href="tel:9058358377">905 - 835 - 8377</a>
              <a className="contact-number" href="tel:9058358177">905 - 835 - 8717</a>
        </div>
        <div className="contact-address-container">
          <p className="contact-address-title">Restaurant Address</p>
          <p className="contact-address">17 Charlotte St. Port Colborne</p>
          <img className="map" src={Map} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Contact;
